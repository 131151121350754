import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import SpeakerLinkWrapper from "./speaker-link-wrapper";

const BoxSpeaker = ({ data, showProgram }) => {
  return (
    <SpeakerLinkWrapper item={data} title={data.fullName} className="box-speaker">
      <GatsbyImage
        image={getImage(data.image)}
        alt={data.fullname || ""}
        className="box-speaker__img"
        loading="eager"
      />
      <div className="box-speaker__name text-uppercase">
        <span className="d-block">{data.name}</span> <span className="d-block">{data.surname}</span>
      </div>
      {data.role && <div className="box-speaker__role text-uppercase">{data.role}</div>}
      {showProgram && <div className="box-speaker__type">{data.program}</div>}
    </SpeakerLinkWrapper>
  );
};

export default BoxSpeaker;
