import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "../components/link";
import VideoOverlay from "./video-overlay";

const VideoEdizione = () => {
  const [show, setShow] = useState(false);

  const { imgvideo } = useStaticQuery(graphql`
    {
      imgvideo: file(relativePath: { eq: "bg-video.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <>
      <section className="section">
        <div className="box-video">
          <GatsbyImage
            image={getImage(imgvideo)}
            alt="img background video"
            objectPosition="center bottom"
            className="box-video__bg-img"
          />
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-video__title text-uppercase">IID & IIC edizione 2023</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-5 offset-sm-1 col-xl-3 offset-xl-2 text-center">
                <button
                  type="button"
                  title="Guarda il video"
                  aria-label="modale che mostra il video"
                  className="btn-link"
                  onClick={() => setShow(true)}
                >
                  <span className="btn-link__text">
                    Guarda il <span className="d-block bold">video</span>
                  </span>
                  <span className="btn-link__circle"></span>
                </button>
              </div>
              <div className="col-12 col-sm-5 col-xl-4 offset-xl-2 text-center text-md-start">
                <Link to="/edizioni-passate/" className="btn-link">
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">
                    Scopri <span className="d-block bold">di più</span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <VideoOverlay show={show} onHide={() => setShow(false)} />
    </>
  );
};

export default VideoEdizione;
